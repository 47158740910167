.spinners {
  background: #00205bb8;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 99;
}

.flexSpinner {
  width: 100% !important;
  position: fixed;
  top: calc(51% - 40px);
  left: calc(51% - 40px);
  display: block;
  z-index: 9;
}
