:root {
  --primary: #001b4d;
  --blue: #063b9e;
  --lightblue: #f0f4f6;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

.color-blue {
  color: var(--blue) !important;
}

.bg-lightblue {
  background-color: var(--lightblue) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.pt-0 {
  padding-top: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mr-2 {
  margin-right: 2px;
}

.fw-bold {
  font-weight: bold !important;
}

.row {
  position: relative;
}

.wrapper {
  background-color: #fff;
  color: #444;
  /* Use flexbox */
  display: flex;
  height: 400px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MuiFormLabel-root {
  z-index: 0 !important;
}

@media screen and (max-width: 900px) {
  .MuiDataGrid-columnHeader {
    flex: 1 !important;
    max-width: 115px !important;
  }

  .MuiDataGrid-cell {
    max-width: 115px !important;
  }
}

@media screen and (max-width: 1200px) {
  .MuiDataGrid-columnHeader {
    flex: 1 !important;
    max-width: 115px !important;
  }

  .MuiDataGrid-cell {
    max-width: 115px !important;
  }
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 900;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

.css-1xq248k-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-start;
}

.css-1xq248k-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer,
.css-1xq248k-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row !important;
}

.css-1xq248k-MuiDataGrid-root.MuiDataGrid-autoHeight {
  height: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 5.5px 14px;
}

.MuiFormControl-root {
  margin: 10px 5px 15px 5px !important;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #00205b;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* for Loader */

#outlined-error {
  border: 1px solid red !important;
}

footer {
  position: relative;
  z-index: 9999;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00205b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #022e80;
}

.ds-modal10 .MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
}

.MuiDataGrid-virtualScroller {
  overflow-x: scroll !important;
}

.MuiDataGrid-columnHeadersInner>div[aria-rowindex='1'] {
  background-color: #f0f4f6;
}

.MuiDataGrid-columnHeaderTitle {
  color: #00205b !important;
  font-weight: 700;
}

.MuiDataGrid-sortIcon,
.css-ptiqhd-MuiSvgIcon-root {
  color: #03329a !important;
  font-weight: 500;
}

.css-gpfkrt-MuiDataGrid-root .odd {
  background-color: #f0f4f6;
}

.css-gpfkrt-MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 0px solid !important;
  justify-content: center !important;
}

.MuiGrid-root.wrapper {
  box-shadow: 0px 2px 4px rgb(0 32 91 / 44%);
  border-radius: 4px;
  border: 0px;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #00205b !important;
  border-radius: 4px;
}

.MuiDataGrid-root {
  border: 0px;
}

.MuiButton-root.ds-button--primary {
  background-color: #00205b !important;
  color: #fff;
}

.MuiTabs-scroller {
  background-color: #f0f4f6;
}

.css-mmqqsa-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00205b !important;
  background-color: #fff;
}

.PrivateTabIndicator-colorPrimary-4 {
  background-color: #00205b;
}

.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 4px !important;
  top: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #00205b !important;
}

.MuiTab-root:hover,
.ds-button--secondary:hover {
  background-color: #00205b !important;

  color: #fff !important;
}

.dropdown {
  padding: 0px !important;
}

.css-a5rdam-MuiGrid-root {
  max-height: 769px;
}

.css-8rbi8o-MuiDrawer-docked .MuiDrawer-paper {
  z-index: 0;
  position: relative;
  min-height: 100vh;
}

.MuiListItemText-primary {
  font-size: 14px !important;
}

.MuiListItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #00205b2b;
}

.css-1m8x6cq-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer,
.css-1m8x6cq-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: inherit !important;
}

.iconsSvg {
  opacity: 0.6;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: none !important;
  color: #002d80;
  opacity: 0.9 !important;
}

.right-form .Mui-disabled,
.right-form .MuiOutlinedInput-notchedOutline {
  -webkit-text-fill-color: #282e3a !important;
}

button[aria-label='Export'],
button[aria-label='Show filters'] {
  min-width: 0px !important;
}

button[aria-label='Export'] span,
button[aria-label='Show filters'] span {
  margin-right: 0px !important;
}

.css-6klit5-MuiAccordionDetails-root {
  padding: 10px 0px;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  border: 1px solid #1976d2 !important;
}

.MuiDataGrid-columnHeader:focus .Mui-error,
.MuiDataGrid-cell:focus .Mui-error {
  border: 0px solid #ff0000 !important;
}

.wrapper .MuiGrid-container>.MuiGrid-container {
  margin: 0px 0px;
}

.right-form>.MuiGrid-container {
  margin-left: 0px;
  width: 100%;
}

.icon-spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

.ds-accordion25-actions {
  display: none;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.w-100 {
  width: 100% !important;
}

.w-250 {
  width: 250px !important;
}

.h-100 {
  height: 100% !important;
}

.grid-header-filter-input {
  height: 100% !important;
  padding: 8px 0px;
}

.grid-header-filter-input div {
  height: 100% !important;
}

.wrapper>div>div:last-child {
  overflow-y: auto;
}

.MuiInputBase-multiline {
  display: block;
  width: 250px;
  position: absolute;
  top: 0px;
}

.MuiBox-root .css-kkncr0 {
  position: relative;
}

textarea {
  padding: 5px 15px;
}

.innerCircle {
  text-align: center;
  margin: auto !important;
  top: 32%;
}

.ds-fileupload23-files {
  text-align: center !important;
  margin: 10px auto;
}

section[class^='ds-dialog'] button[class*='-close'] {
  position: absolute !important;
}

.input-small input,
.input-small textarea {
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.input-small input {
  height: 2rem;
}

main div[role='alert'] {
  z-index: 1 !important;
}

.disabled-input input[readonly],
.disabled-input input[disabled],
.disabled-input textarea[readonly],
.disabled-input input[readonly] button[disabled] {
  opacity: 0.5;
}